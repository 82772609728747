<template>
  <BaseDraggableModal
      :name="modal_name"
      max-height="80vh"
      width="70vw"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      :ref="modal_name">
    <v-card :loading="loading" :disabled="loading">
      <BaseModal>
        <v-toolbar class="draggable_selector" slot="header" dark color="primary" dense flat>
          <v-btn icon @click.stop="reset">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{ page.title }}</v-toolbar-title>
          <v-spacer/>
          <v-btn text small @click.stop="save">
            <v-icon>mdi-content-save</v-icon>
          </v-btn>
        </v-toolbar>
        <div slot="content">
          <v-divider/>
          <v-card-text class="pt-5">
            <template v-for="field in usual_fields">
              <base-field
                  :key="field.name"
                  :value="record[field.name]"
                  :field="field"
                  @change="usualFieldDataChanged"/>
            </template>
            <template v-for="field in custom_fields">
              <base-field
                  :key="field.name"
                  :value="record.custom_fields[field.name]"
                  :field="field"
                  @change="customFieldDataChanged"/>
            </template>
            <base-addresses v-model="record.addresses" address_type="visiting" label="contact_address" mapID="map1"/>
            <v-switch :label="$store.getters.translate('billing_address_the_same')" v-model="hide_billing_address"/>
            <base-addresses v-if="!hide_billing_address" v-model="record.addresses" address_type="billing" label="billing_address" mapID="map2"/>
            <base-tags v-if="tags_required" v-model="record.tags" :module_name="page.name"/>
          </v-card-text>
        </div>
      </BaseModal>
    </v-card>
  </BaseDraggableModal>
</template>

<script>
import BaseAddresses from "../../components/commonComponents/BaseAddresses";
import BaseModal from "../../components/commonComponents/BaseModal";
import BaseDraggableModal from "../../components/commonComponents/BaseDraggableModal";
import helpFunctions from "../../plugins/helpFunctions";

export default {
  components: {
    BaseAddresses,
    BaseModal,
    BaseDraggableModal,
  },
  props: ["modal_name"],
  data() {
    return {
      page: {
        title: this.$store.getters.translate("create_company"),
        name: "companies",
        model: "company"
      },
      loading: false,
      record: {
        custom_fields: {},
        addresses: [],
      },
      usual_fields: [],
      custom_fields: [],
      tags_required: false,
      hide_billing_address: false,
    };
  },
  created() {
    this.getFields();
  },
  methods: {
    ...helpFunctions.modal_functions,
    save() {
      if(!this.loading) {
        let error = false;
        this.usual_fields.forEach((field) => {
          if (field.mandatory && field.field_type !== 'boolean' && (!this.record[field.name] || this.record[field.name] === '')) {
            this.$toasted.error(this.$store.getters.translate("required_field") + ": " + this.$store.getters.translate(field.name));
            error = true;
          }
        });
        if(!error) {
          if (this.record.phonenumber && this.record.phonenumber.charAt(0) !== '+') {
            this.$toasted.error(this.$store.getters.translate("international_number_error"));
          } else {
            this.loading = true;
            this.record['this_company'] = 1;
            this.$http
                .post(this.$store.getters.appUrl + "v2/companies", this.record)
                .then((response) => {
                  this.loading = false;
                  if (response.status == 200) {
                    this.$toasted.success(this.$store.getters.translate("successfully_saved"));
                    this.$router.push("/companies/" + response.data.id);
                    this.reset();
                  } else {
                    var errorMessage = response.data.message;
                    var errors = Object.keys(response.data.message);
                    this.$toasted.error([errorMessage[errors[0]]]);
                  }
                })
                .catch((error) => {
                  if (error.response.status === 403) {
                    this.$toasted.error(this.$store.getters.translate('no_access'))
                  } else {
                    this.$toasted.error(error);
                  }
                  this.loading = false;
                });
          }
        }
      }
    },
    reset() {
      this.record = {
        custom_fields: {},
      };
      this.closeModal(this.modal_name);
    },
    usualFieldDataChanged(data) {
      this.record[data[0]] = data[1];
    },
    customFieldDataChanged(data) {
      this.record.custom_fields[data[0]] = data[1];
    },
    async getFields() {
      this.$http
          .get(this.$store.getters.appUrl + "v2/fields?source=site&show_on_creation=true&model_type=" + this.page.model)
          .then((response) => {
            this.custom_fields = [];
            this.usual_fields = [];
            response.data.data.forEach((field) => {
              if (field.custom_field == 1) {
                this.custom_fields.push(field);
              }
              else if(field.name === 'tags') {
                this.tags_required = true;
              }
              else {
                if(field.field_type === 'select_single' || field.field_type === 'select_multiple') {
                  for (let i = 0; i < field.available_options.length; i++) {
                    field.available_options[i] = {
                      text: this.$store.getters.translate(field.available_options[i]),
                      value: field.available_options[i],
                    };
                  }
                }
                this.usual_fields.push(field);
              }
            });
            this.loading = false;
          })
          .catch((error) => {
            if (error.response.status === 403) {
              this.$toasted.error(this.$store.getters.translate('no_access'))
            } else {
              this.$toasted.error(error);
            }
            this.loading = false;
          });
    },
  },
  watch: {
    hide_billing_address: {
      handler() {
        if (this.hide_billing_address) {
          this.record.addresses.forEach((address) => {
            if (address.type === 'billing') {
              this.record.addresses = this.record.addresses.filter((address2) => address2.formatted_address !== address.formatted_address);
            } else if (address.type === 'visiting' && !address.primary) {
              address.primary = 1;
            }
          });
        } else {
          this.record.addresses.forEach((address) => {
            if (address.type === 'visiting' && address.primary) {
              address.primary = 0;
            }
          });
        }
      },
    },
  },
};
</script>
